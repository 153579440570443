import React from 'react';

import UnauthenticatedRoute from '../../components/UnauthenticatedRoute';
import { ResetPassword } from '../../components/ucet/ZapomenuteHeslo';

const ZapomenuteHeslo = () => (
  <UnauthenticatedRoute component={ResetPassword} />
);

export default ZapomenuteHeslo;
