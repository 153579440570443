import React, { useState } from 'react';

import { Layout } from '../../../components/shared/Layout';
import { Email } from './Email';
import { Send } from './Send';

export const ResetPassword = () => {
  const [email, setEmail] = useState(null);
  const [step, setStep] = useState(null);

  const onEmailSubmit = ({ email }) => {
    setEmail(email);
    setStep('send');
  };

  const renderStep = () => {
    if (step === 'send') {
      return <Send email={email} />;
    }
    return <Email onSubmit={onEmailSubmit} />;
  };

  return <Layout showLoginButton={false}>{renderStep()}</Layout>;
};
